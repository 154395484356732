import ComponentHelperBase from "./ComponentHelperBase";
import { VisitModel, VisitsStatusCode } from "../Models/VisitModel";
import { GoalModel } from "../Models/GoalModel";
import GlobalStateModel from "../Models/GlobalStateModel";
import TokenModel from "../Models/TokenModel";
import GoalsAndVisits from "./GoalsAndVisits";
import { TrainingProgrammeModel } from "../Models/TrainingProgrammeModel";
import { ProgrammeModel } from "../Models/ProgrammeModel";
import TrainingPlanUnitStandardModel from "../Models/TrainingPlanUnitStandardModel";
import TrainingPlanUnitStandard from "./TrainingPlanUnitStandard";
import { ItpuUpdate } from "../Common/TrainingPlanUnitStandardManager";


export default class GoalsHelper extends ComponentHelperBase {

    _globalState: GlobalStateModel;
    _takeTop: number;
    _visitType: number[];
    _goalStatus: number[];

    constructor(globalState: GlobalStateModel, takeTop: number, visitType: number[], goalStatus:number[]) {
        super();
        this._globalState = globalState;
        this._takeTop = takeTop;
        this._visitType = visitType;
        this._goalStatus = goalStatus;
    }

    _goalsAndVisits: GoalsAndVisits[] | undefined;
    _trainingPlanUnitStandards: TrainingPlanUnitStandard[] | undefined;
    async build() {
        this._goalsAndVisits = [];
        this._trainingPlanUnitStandards = [];
        let visitModel = new VisitModel(this._globalState.tokenModel as TokenModel);
        let tpusModel = new TrainingPlanUnitStandardModel(this._globalState.tokenModel as TokenModel);
        
        let visits = await visitModel.retrieveVisitsWithGoals(this._globalState.trainingPlanObj?._tims_trainingprogrammeid_value as string, this._visitType, this._takeTop, this._goalStatus);
        let tpus = await tpusModel.retrieveAll()
        for(let index = 0; index < tpus.length; index++){
            const element = tpus[index];
            let tpu = new TrainingPlanUnitStandard(element);
            await tpu.build()
            if(tpu._result == null){
                this._trainingPlanUnitStandards.push(tpu)
            }
        }

        for (let index = 0; index < visits.length; index++) {
            const element = visits[index];
            this._goalsAndVisits.push(new GoalsAndVisits(element));
        }
    }

    async createGoal(description: string, dueDate: Date, visitId: string, tpusId:string, taReminderType: number[], learnerReminderType: number[], taReminderDate: Date | undefined, workplaceReminderDate: Date | undefined, learnerReminderDate:Date | undefined, goalType:number | undefined) {
        let goalModel = new GoalModel(this._globalState.tokenModel as TokenModel);
        return await goalModel.createGoals({
            fus_description: description, fus_duedate: dueDate, fus_learnervisitid: visitId,
            tims_trainingplanunitstandard:tpusId,
            fus_learnerid: this._globalState.contact?.contactid,
            fus_trainingplanid: this._globalState.trainingPlanObj?.tims_trainingplanid,
            tims_trainingadviserremindertype:taReminderType,
            tims_trainingadviserreminderdate:taReminderDate,
            tims_learnerremindertype:learnerReminderType,
            tims_learnerreminderdate:learnerReminderDate,
            tims_workplacereminderdate:workplaceReminderDate,
            tims_goaltype:goalType
        });
    }

    async updateVisitStatus(statuscode: number, visitId: string) {
        let visitModel = new VisitModel(this._globalState.tokenModel as TokenModel);
        let response = visitModel.updateVisit({
            statuscode: statuscode, tims_dateofvisit: undefined,
            tims_learner: undefined, tims_visittype: undefined, trainingprogrammeId: undefined, tims_macagreementId: undefined, tims_visittypeothertext: undefined, tims_trainingplanId: undefined
        }, visitId);

        return response;
    }

    async updateVisit(tims_dateofvisit: Date, tims_visittype: number, visitTypeOther: string | undefined, visitId: string) {
        let visitModel = new VisitModel(this._globalState.tokenModel as TokenModel);
        let response = visitModel.updateVisit({
            statuscode: undefined, tims_dateofvisit: tims_dateofvisit,
            tims_learner: undefined, tims_visittype: tims_visittype, trainingprogrammeId: undefined, tims_macagreementId: undefined, tims_visittypeothertext: visitTypeOther, tims_trainingplanId: undefined
        }, visitId);

        return response;
    }

    async createVisit(tims_dateofvisit: Date, visitType: number, visitTypeOther: string | undefined) {
        let visitModel = new VisitModel(this._globalState.tokenModel as TokenModel);
        let tims_macagreementId: string | undefined = undefined;
        let pgRep = new ProgrammeModel(this._globalState.tokenModel as TokenModel);
        let programmeForTP = await pgRep.retrieveProgramme((this._globalState.trainingProgrammeObj?._tims_programmeid_value as string)) as ProgrammeModel;
        if (programmeForTP.tims_pathway == 6) {
            let tpRep = new TrainingProgrammeModel(this._globalState.tokenModel as TokenModel);
            let nzaAgreements = await tpRep.getNZAAgreementForTP(this._globalState.trainingProgrammeObj?.tims_trainingprogrammeid as string);
            if (nzaAgreements.length > 0)
                tims_macagreementId = nzaAgreements[0].tims_macagreementid as string
        }

        let obj = await visitModel.createVisit(
            {
                statuscode: VisitsStatusCode.Pending,
                tims_dateofvisit: tims_dateofvisit,
                tims_visittype: visitType,
                trainingprogrammeId: this._globalState.trainingProgrammeObj?.tims_trainingprogrammeid,
                tims_learner: this._globalState.contact?.contactid,
                tims_macagreementId: tims_macagreementId,
                tims_visittypeothertext: visitTypeOther,
                tims_trainingplanId: this._globalState.trainingPlanObj?.tims_trainingplanid
            }
        );

        let visit = visitModel.createVisitsModel([obj]);
        return visit[0];
    }

    async updateGoal(description: string, dueDate: Date, goalId: string, tpusId: string,taReminderType: number[], learnerReminderType: number[], taReminderDate: Date | undefined, workplaceReminderDate: Date | undefined, learnerReminderDate:Date | undefined, goalType:number | undefined) {
        let goalModel = new GoalModel(this._globalState.tokenModel as TokenModel);
        let goalUpdate = {
            fus_description: description, fus_duedate: dueDate,
            fus_learnerid: undefined, fus_learnervisitid: undefined, fus_trainingplanid: undefined,
            tims_trainingplanunitstandard:tpusId,
            tims_trainingadviserremindertype: taReminderType, tims_learnerremindertype: learnerReminderType,
            tims_trainingadviserreminderdate: taReminderDate, tims_learnerreminderdate: learnerReminderDate,
            tims_workplacereminderdate: workplaceReminderDate,
            tims_goaltype:goalType
        };
        return await goalModel.updateGoal(goalUpdate, goalId);
    }

    async deleteGoal(goalId: string) {
        let goalModel = new GoalModel(this._globalState.tokenModel as TokenModel);
        let response = await goalModel.deleteGoal(goalId);
        return response;
    }

    async deactivateGoal(goalId: string) {
        let goalModel = new GoalModel(this._globalState.tokenModel as TokenModel);
        return goalModel.deactivateGoal(goalId);
    }

    async updateTpus(tpusId:string, assesmentMethod:number, goalId:string, lmsStatus:number){
        let tpusModel = new TrainingPlanUnitStandardModel(this._globalState.tokenModel as TokenModel)
        return tpusModel.updateTPUS(tpusId,  {assessmentmethod:assesmentMethod, goalId:goalId, lmsstatus: lmsStatus} as ItpuUpdate)
    }
    
    get rows(): ComponentHelperBase[] {
        return this._goalsAndVisits as GoalsAndVisits[];
    }

    get availableTpus(): TrainingPlanUnitStandard[] {
        return this._trainingPlanUnitStandards as TrainingPlanUnitStandard[]
    }

}