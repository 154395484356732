import React, { useState } from "react";
import { useBoolean, useId } from '@fluentui/react-hooks';
import { Stack, Checkbox, StackItem, MessageBar, MessageBarType, PrimaryButton, DefaultButton, Modal, IconButton, IDragOptions, ContextualMenu, Dialog, DialogFooter, DialogType, Icon } from "@fluentui/react";
import {
  Dropdown,
  IDropdownStyles,
  IDropdownOption,
} from "@fluentui/react/lib/Dropdown";
import "../assets/css/MasterControl.css";
import { Accordion } from "./Accordion";
import DetailsControl from "./DetailsControl";
import TokenModel from "../Models/TokenModel";
import TrainingPlanModel from "../Models/TrainingPlanModel";
import { TrainingProgrammeModel } from "../Models/TrainingProgrammeModel";
import { ContactModel } from "../Models/ContactModel";
import { ProgrammeModel } from "../Models/ProgrammeModel";
import Common from "../Common/Common";
import { UnitStandardModel } from "../Models/UnitStandardModel";
import { FrameworkElementModel } from "../Models/FrameworkElementModel";
import { TrainingContractModel } from "../Models/TrainingContractModel";
import { CompulsarySection } from "./CompulsorySection";
import Loader from "react-loader-spinner";
import GlobalState from "../GlobalState";
import GlobalStateModel, { DirtyMode } from "../Models/GlobalStateModel";
import { ElectiveGroupSection } from "./ElectiveGroupSection";
import { ProgrammeElectiveSections } from "./ProgrammeElectiveSections";
import ButtonPanelControl from "./ButtonPanelControl";
import logo from "../assets/images/PrimaryITO_CMYK BLACK text-01.png";
import { SummaryObject } from "../Controls/ElectiveGroupControl";
import { ReorderControl } from "./ReorderControl";
import { GoalsControl } from "./GoalsControl";
import { TemplateSelector } from "../Controls/TemplateSelector";
import CacheManager from "../Common/CacheManager";
import Emitter from "../Common/EventEmitter";
import { Events, RequestType, TrainingPlanStatuses, Views } from "../Common/Enum";
import { Collapse } from 'react-collapse';
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import SendEmailHelper from "../ComponentHelpers/SendEmailHelper";
import { Header } from "./Header";
import { TrainingPlanViewModel } from "../Models/TrainingPlanViewModel";
import ReorderHelper from '../ComponentHelpers/ReorderHelper';

import { ReorderControlProvider } from "../Contexts/ReorderControlContext";

const optionsViewDD: IDropdownOption[] = [
  { key: "Editableaddresult", text: "Editable (add result/comment)",  },
  { key: "Editablemanageunits", text: "Editable (manage Units)" },
  { key: "reorder", text: "Re-order/Goals and Visits" }
];

const portalOptionsViewDD: IDropdownOption[] = optionsViewDD.map((option, index) => ({
    key: option.key, text: option.text, hidden: index === 0 || index === 1
}))

const headerLogoStyle = {
  float: "right",
  marginTop: "0.9%",
  width: "8%"
};

interface TrainingPlanViewsContextType {
  trainingPlanViews: IDropdownOption[]; // Replace with the actual type of your trainingPlanViews
  updateTrainingPlanViews: React.Dispatch<React.SetStateAction<IDropdownOption[]>>; // Use React.Dispatch for setState functions,
  onViewChange: (index: number) => void;
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 250 },
};
interface MasterControlState {
  tokenModel: TokenModel;
  trainingPlanObj: TrainingPlanModel;
  trainingProgrammeObj: TrainingProgrammeModel;
  contact: ContactModel;
  programmeObj: ProgrammeModel;
  renderChildren: boolean;
  trainingPlanViews:IDropdownOption[]
}

interface MasterControlStateB {
  summaryObj: SummaryObject,
  reloadControls: false
}

interface MasterControlStateC {
  showMessageBar: boolean;
  sectionWarnings: ISectionWarning[];
  allWarnings: string[]
}

interface ISectionWarning {
  sectionID: string,
  warnings: string[]
}

interface EmailSendingState {
  isDialogOpen: boolean
}
interface MasterControlProps {
  token: TokenModel;
  trainingPlanId: string;
}

export interface IMessagebarObject {
  isSuccess: boolean;
  errors: string[];
  requestType: RequestType;
  showMessageBar: boolean;
}

export interface IMessagebarObjectWarning {
  showMessageBar: boolean;
  warnings: string[];
}

export interface ReorderContState {
  reorderHelp: ReorderHelper,
  showLoader: boolean,
  trainingPlanView:TrainingPlanViewModel
}

export const TrainingPlanViewsContext = React.createContext<TrainingPlanViewsContextType | undefined>(undefined);
export const MasterControlContextWarnings = React.createContext({});
export const MasterControlContext = React.createContext({});

export const MasterControl: React.FunctionComponent<MasterControlProps> = (props) => {

  const [masterControlState, setMasterControlState] = useState({
    tokenModel: {} as TokenModel,
    trainingPlanObj: {} as TrainingPlanModel,
    contact: {} as ContactModel,
    programmeObj: {} as ProgrammeModel,
    trainingProgrammeObj: {} as TrainingProgrammeModel,
    renderChildren: false, 
  } as MasterControlState);

  const globalAppContext = React.useContext(GlobalState);

  const [ddSelectedView, setDDSelectedView] = useState("Editableaddresult");
  const [trainingPlanViews, updateTrainingPlanViews] = useState<IDropdownOption[]>(optionsViewDD)

  const [messageBarState, setMessageBarState] = useState({ isSuccess: false, errors: [], requestType: 1, showMessageBar: false, warnings: [] } as IMessagebarObject)
  const [isDetailSectionDisplayed, setIsDetailSectionDisplayed] = useState(true); 
  const [printing, setPrinting] = useState(false)

  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');


  const dialogStyles = { main: { maxWidth: 450 } };
  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  };

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
      dragOptions: isDraggable ? dragOptions : undefined,
    }),
    [isDraggable, labelId, subTextId],
  );

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Unsaved Changes',
    closeButtonAriaLabel: 'Close',
    subText: 'Please save changes before sending email.',
  };

  const masterControlReducer = (state: MasterControlStateB, action: any) => {

    switch (action.type) {
      case "UPDATE_SUMMARY":
        {
          if (action.obj.checked)
            return { summaryObj: { 
              id: state.summaryObj.id, 
              selected: state.summaryObj.selected + action.obj.summaryObj.selected, 
              selectedMin: state.summaryObj.selectedMin + action.obj.summaryObj.selectedMin, 
              selectedMax: state.summaryObj.selectedMax + action.obj.summaryObj.selectedMax, 
              remaining: state.summaryObj.remaining + action.obj.summaryObj.remaining, 
              achieved: state.summaryObj.achieved + action.obj.summaryObj.achieved 
            }, reloadControls: false } as MasterControlStateB
          else
            return { summaryObj: { 
              id: state.summaryObj.id, 
              selected: state.summaryObj.selected - action.obj.summaryObj.selected, 
              selectedMin: state.summaryObj.selectedMin - action.obj.summaryObj.selectedMin, 
              selectedMax: state.summaryObj.selectedMax - action.obj.summaryObj.selectedMax, 
              remaining: state.summaryObj.remaining - action.obj.summaryObj.remaining, 
              achieved: state.summaryObj.achieved - action.obj.summaryObj.achieved 
            }, reloadControls: false } as MasterControlStateB
        }
      case "INIT_SUMMARY":
        return { summaryObj: { 
          id: state.summaryObj.id, 
          selected: state.summaryObj.selected + action.obj.selected, 
          selectedMin: state.summaryObj.selectedMin + action.obj.selectedMin, 
          selectedMax: state.summaryObj.selectedMax + action.obj.selectedMax, 
          remaining: state.summaryObj.remaining + action.obj.remaining, 
          achieved: state.summaryObj.achieved + action.obj.achieved }, 
          reloadControls: false } as MasterControlStateB
      
      case "POST_TEMPLATE": {
        if (!action.obj.fromTimeout)
          setTimeout(() => {
            dispatch({
              type: "POST_TEMPLATE", obj: { reloadControls: false, fromTimeout: true }
            });
          }, 500);

        return { summaryObj: { achieved: 0, remaining: 0, selected: 0, selectedMin: 0, selectedMax: 0, id: state.summaryObj.id }, reloadControls: action.obj.reloadControls } as MasterControlStateB
      }
      case "RESET_SUMMARY": {
        return { summaryObj: { achieved: 0, remaining: 0, selected: 0, selectedMin: 0, selectedMax: 0, id: state.summaryObj.id }, reloadControls: false } as MasterControlStateB
      }
      default:
        break;
    }
    return state;
  }


  const masterControlWarningReducer = (state: MasterControlStateC, action: any) => {
    switch (action.type) {
      case "UPDATE_WARNINGS":
        let sectionWarnings = [...state.sectionWarnings];
        let allWarnings = [] as string[];
        let electiveSectionIndex = state.sectionWarnings.findIndex(warning => warning.sectionID == action.obj.sectionID);

        if (electiveSectionIndex !== -1) {
          // Existing elective section with warnings so update the warnings
          sectionWarnings[electiveSectionIndex] = { ...sectionWarnings[electiveSectionIndex], warnings: action.obj.warnings };
        } else {
          // New elective section so add to array
          sectionWarnings.push({ sectionID: action.obj.sectionID, warnings: action.obj.warnings });
        }

        for (const sectionWarning of sectionWarnings) {
          allWarnings = allWarnings.concat(sectionWarning.warnings);
        }

        return { showMessageBar: allWarnings.length != 0, sectionWarnings: sectionWarnings, allWarnings: allWarnings };

      case "HIDE_WARNINGS":
        return { ...state, showMessageBar: false };

      //This case makes sure when there is a result created for a pre req unistandard alerts refresh.
      case "REMOVE_WARNINGS_AFTER_RESULT":
        {
          let removalIndex: undefined | number = undefined;
          let allWarnings = [...state.allWarnings];
          for (let index = 0; index < allWarnings.length; index++) {
            var element = allWarnings[index];
            if (element.includes(action.obj.frameworkelementcode) && !element.includes(","))  //Remove alert
              removalIndex = index;
            else if (element.includes(action.obj.frameworkelementcode) && element.includes(","))
              if (element.includes(action.obj.frameworkelementcode + ","))
                allWarnings[index] = element.replace(action.obj.frameworkelementcode + ",", "");
              else if (element.includes("," + action.obj.frameworkelementcode))
                allWarnings[index] = element.replace("," + action.obj.frameworkelementcode, "");
          }
          if (removalIndex != undefined)
            allWarnings.splice(removalIndex, 1);

          return { ...state, showMessageBar: allWarnings.length != 0, allWarnings: allWarnings };
        }

    }
    return state;
  }

  const [masterControlStateC, dispatchC] = React.useReducer(masterControlWarningReducer, { showMessageBar: false, sectionWarnings: [], allWarnings: [] });

  const [masterControlStateB, dispatch] = React.useReducer(masterControlReducer, { summaryObj: { selectedItems: 0, achieved: 0, remaining: 0, selected: 0, selectedMin:0, selectedMax:0, id: Common.newGuid(), preqReqPUS: [] }, reloadControls: false } as MasterControlStateB);

  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const gridPanelRef = React.useRef<HTMLDivElement | null>(null);

  // If a new custom view is added, switch to that view
  React.useEffect(() => {
    onViewChange(trainingPlanViews.length - 1)
  },[trainingPlanViews])

  // Show details section when printing
  React.useEffect(() => {   
    if(printing){
      window.print();
      setPrinting(false)
    }
  }, [printing])

  React.useEffect(() => {
    const initializeDataLoad = async () => {
      let token = props.token
      var isPortalMode = props.token.loginType === 'portal';
      var trainingPlanModel = new TrainingPlanModel(token);
      var trainingProgrammeModel = new TrainingProgrammeModel(token);
      var contactModel = new ContactModel(token);
      var programmeModel = new ProgrammeModel(token);
      var unitStandardModel = new UnitStandardModel(token);
      var frameworkElemModel = new FrameworkElementModel(token);
      var trainingContractModel = new TrainingContractModel(token);
      var trainingPlanViewModel = new TrainingPlanViewModel(token)
      let jsonObj = await trainingPlanModel.retrieveTrainingPlan(props.trainingPlanId as string, false);
      var trainingPlanObj = jsonObj as TrainingPlanModel;
      //Security role check
      //let userRoles = await Common.getUserRoles(token, token.userId as string);
      //let userRole = userRoles.find(item => item["Role.name"] == "3 - Enrolments" || item["Role.name"] == "System Administrator");
      // if (userRole == undefined) {
      //   removeManageUnitsView();
      // }
      // Security Role check ends

      //Remove manage units view if plan is inactive
      if (trainingPlanObj.statuscode == TrainingPlanStatuses.Already_Awarded || trainingPlanObj.statuscode == TrainingPlanStatuses.Cancelled || trainingPlanObj.statuscode == TrainingPlanStatuses.Completed_by_Trainee)
        removeManageUnitsView();

      // if(!isPortalMode){
        var trainingPlanViews = await trainingPlanViewModel.retrieveTrainingPlanViewsForUser(token.userId as string) as TrainingPlanViewModel[]
        trainingPlanViews.forEach(view => {
          optionsViewDD.push( { key: view.tims_trainingplanviewid as string, text: view.tims_name as string },)
        })
        updateTrainingPlanViews(optionsViewDD)
      // }
      var trainingProgrammeObj = (await trainingProgrammeModel.retrieveTrainingProgramme(trainingPlanObj._tims_trainingprogrammeid_value as string, false)) as TrainingProgrammeModel;
      var trainingContractObj = await trainingContractModel.retrieveTrainingContract(trainingProgrammeObj._tims_trainingcontractid_value as string) as TrainingContractModel;
      var contact = (await contactModel.retrieveContact(trainingProgrammeObj._tims_contacttraineeid_value as string)) as ContactModel;
      var programmeObj = (await programmeModel.retrieveProgramme(trainingPlanObj._tims_programmeid_value as string)) as ProgrammeModel;

      var defaultAssessor = await contactModel.retrieveAssessorEntityReference(trainingPlanObj.tims_trainingplanid as string);
      if (defaultAssessor != null) {
        var fitlerCriteria = await frameworkElemModel.generateFrameworkElementFilterForAssessor(defaultAssessor as ContactModel);
        await unitStandardModel.initialiseAssessorUnitStandards(defaultAssessor as ContactModel, fitlerCriteria);
        trainingPlanObj.defaultAccessor = defaultAssessor as ContactModel;
      }

      let globalContext = {
        tokenModel: token,
        trainingPlanObj: trainingPlanObj,
        contact: contact,
        programmeObj: programmeObj,
        trainingProgrammeObj: trainingProgrammeObj,
        trainingContractObj: trainingContractObj,
        isEditMode: false,
        isPreviewModeOnly: false,
        showSectionRules:true,
        isDirty: {
          isElecGrpSectionDirty: false, isPrgrmElecSec: false
        },
        isCompliant:
        {
          isElecGrpCompliant: true,
          isPrgrmElecSecCompliant: true
        }, selectedView: Views.Readonly
        , IsFullScreenLoaderVisible: false
        , IsPortalMode: isPortalMode
      } as GlobalStateModel
      await CacheManager.initializeCache(globalContext);
      globalAppContext.setGlobalState(globalContext);

      setDDSelectedView(isPortalMode ? "reorder" : "Editableaddresult");

      let stateObj = {
        tokenModel: token,
        trainingPlanObj: trainingPlanObj,
        contact: contact,
        programmeObj: programmeObj,
        trainingProgrammeObj: trainingProgrammeObj,
        trainingPlanViews:optionsViewDD,
        renderChildren: true,
      } as MasterControlState;
      setMasterControlState(stateObj);

      Emitter.on(Events.SetMessageBar, (arg: IMessagebarObject) => {
        setMessageBarState(arg);
      });


    }

    initializeDataLoad();

    return () => {
      Emitter.off(Events.SetMessageBar, () => { });
    };
  }, []);

  const removeManageUnitsView = () => {
    let ddManageUnitsOption = optionsViewDD.find(item => item.key == "Editablemanageunits");
    if (ddManageUnitsOption != undefined) 
      optionsViewDD.splice(optionsViewDD.lastIndexOf(ddManageUnitsOption), 1)
  }

  const getProgressBarValue = () => {
    let percentage = masterControlStateB.summaryObj.achieved / masterControlStateB.summaryObj.selected;
    let isNan = Number.isNaN(percentage)
    if (isNan)
      return 0;
    return percentage;
  }

  const isCompliant = () => {
    if (!globalAppContext.isCompliant?.isElecGrpCompliant || !globalAppContext.isCompliant?.isPrgrmElecSecCompliant)
      return false;
    let minCredits = (masterControlState.programmeObj.tims_minimumcredits as number) ?? 0
    let maxCredits = (masterControlState.programmeObj.tims_maximumcredits as number) ?? 1000

    let minCreditsAtLevel = (masterControlState.programmeObj.tims_minimumcreditsonlevel as number) ?? 0
    let maxCreditsAtLevel = (masterControlState.programmeObj.tims_maximumcreditsonlevel as number) ?? 1000

    return (
      (masterControlStateB.summaryObj.selected >= minCredits && masterControlStateB.summaryObj.selected <= maxCredits) &&
      (masterControlStateB.summaryObj.selectedMin >= minCreditsAtLevel && masterControlStateB.summaryObj.selectedMax <= maxCreditsAtLevel)
      )
  }

  const addPageBreaks = () => {
    if (gridPanelRef != null) {
      let childern = gridPanelRef.current?.children;
      if (childern != undefined) {
        for (let index = 0; index < childern.length; index++) {
          const element = childern[index];
          getClientHeightForChildren(element);
        }
      }
    }
  }

  const getClientHeightForChildren = (element: Element, clientHeight: number = 0) => {
    let children = element.children;
    if (children != undefined) {
      for (let index = 0; index < children.length; index++) {
        const element = children[index];
        //Accordion
        if (element.classList.contains("css-161")) {
          clientHeight += element.clientHeight;
          console.log("In Accordion page break logic");
        }//table
        else if (element.classList.contains("css-170") && !element.classList.contains("top-accordion")) {
          clientHeight += element.clientHeight;
          console.log("In table page break logic");
        }
        clientHeight = getClientHeightForChildren(element, clientHeight);
      }
    }
    if (clientHeight > 1000) {
      let pageElem = document.createElement("div");
      pageElem.className = "page-break";
      element.appendChild(pageElem);
      clientHeight = 0;;
    }
    return clientHeight;
  }

  const summarySection = () => {
    return (
    <Header 
      name = "Summary of credits on Plan"
      showRules = {globalAppContext.showSectionRules ?? true}
      isDisabled = {false}
      isCompliant = {isCompliant()}
      progress = {
        {
          achieved:masterControlStateB.summaryObj.achieved,
          remaining:masterControlStateB.summaryObj.remaining
        }
      }
      credits = {
        {
          minAllowed:masterControlState.programmeObj.tims_minimumcredits,
          maxAllowed:masterControlState.programmeObj.tims_maximumcredits,
          selected:masterControlStateB.summaryObj.selected
        }
      }
      levels = {
        {
          minAllowed:masterControlState.programmeObj.tims_minimumcreditsonlevel,
          maxAllowed:masterControlState.programmeObj.tims_maximumcreditsonlevel,
          minAllowedLevel:masterControlState.programmeObj.tims_minimumlevel,
          maxAllowedLevel:masterControlState.programmeObj.tims_maximumlevel,
          selectedAtMinLevel:masterControlStateB.summaryObj.selectedMin,
          selectedAtMaxLevel:masterControlStateB.summaryObj.selectedMax
        }
      }
    >
    </Header>
    );
  }

  const onViewChange = (index: number) => {
    var selectedOption = trainingPlanViews[index];

    if ((ddSelectedView != "Editableaddresult" && ddSelectedView != "Editablemanageunits") && 
    (selectedOption.key == "Editablemanageunits" || selectedOption.key == "Editableaddresult"))
      dispatch({ type: "RESET_SUMMARY" });

    let selectedView = Views.Readonly;
    let isEditMode = false;
    switch (selectedOption.key) {
      case "Editableaddresult":
        selectedView = Views.Readonly;
        isEditMode = false;
        break;

      case "Editablemanageunits":
        selectedView = Views.EditMode;
        isEditMode = true;
        break;

      case "reorder":
        selectedView = Views.Reorder;
        break;

      default:
        selectedView = Views.Custom
        break;
    }
    let globalContext = {
      ...globalAppContext,
      isEditMode: isEditMode,
      selectedView: selectedView,
      selectedCustomView: selectedView == Views.Custom ? selectedOption.key : ''
    } as GlobalStateModel;
    globalAppContext.setGlobalState(globalContext);
    setDDSelectedView(selectedOption.key as string);
  }

  const hideShowSectionRules = () => {
      let globalContext = {
        ...globalAppContext,
        showSectionRules: !globalAppContext.showSectionRules
      } as GlobalStateModel

      globalAppContext.setGlobalState(globalContext)
  }

  const printConvert = (className:string, isPostSave:boolean) => {
      let allElements = contentRef.current?.getElementsByClassName(className);
      if (allElements != undefined) {
        for (let index = 0; index < allElements.length; index++) {
          const element = allElements[index];
          if (isPostSave)
            element.classList.remove(className + "-print");
          else
            element.classList.add(className + "-print");
        }
      }
  }

  const preparePDF = (isPostSave: boolean, gridClassName: string) => {
    
    let pdfClassName = gridClassName + 'PDF'
    let detailsSection = contentRef.current?.getElementsByClassName("ReactCollapse--collapse")
    let sortIcon = contentRef.current?.getElementsByClassName("sort-icon selected")

    if (contentRef != null) {
      if (isPostSave){
        contentRef.current?.setAttribute("style", "width:auto") 
        if(detailsSection && !isDetailSectionDisplayed) detailsSection[0].setAttribute("style", "height: auto; overflow: initial; hidden")
      }
      else{
        if(detailsSection && !isDetailSectionDisplayed) detailsSection[0].setAttribute("style", "")
        contentRef.current?.setAttribute("style", "width:29cm");
      }

      let allgridButtons = contentRef.current?.getElementsByClassName("grid-button");

      printConvert('anchor-style', isPostSave);
      printConvert('header-section', isPostSave);
      printConvert('show-on-print', isPostSave)
      printConvert('hide-on-print', isPostSave)

      let editPanel = contentRef.current?.getElementsByClassName("editPanel");
      let dragAndDropGrid = contentRef.current?.getElementsByClassName(gridClassName);

      let colGroup = contentRef.current?.querySelector(`.${pdfClassName} table colgroup`);

      if (dragAndDropGrid != undefined && dragAndDropGrid.length > 0) {
        if (isPostSave) {
          dragAndDropGrid[0].className = dragAndDropGrid[0].className.replace(` ${pdfClassName}`, "");
          if (colGroup != undefined) {
            let col = document.createElement("col");
            col.setAttribute("width", "50px")
            colGroup.insertBefore(col, colGroup.childNodes[0])
          }

        }
        else {
          dragAndDropGrid[0].className += ` ${pdfClassName}`;
          if (colGroup != undefined)
            colGroup.removeChild(colGroup.childNodes[0]);
          else {
            colGroup = contentRef.current?.querySelector(`.${pdfClassName} table colgroup`);
            if (colGroup != undefined)
              colGroup.removeChild(colGroup.childNodes[0]);
          }
        }
      }

      if(sortIcon != undefined){
        for(let index = 0; index < sortIcon?.length; index++){
          const element = sortIcon[index]
          if (isPostSave)
            element.setAttribute("style", "visibility:visible");
          else
          element.setAttribute("style", "visibility:hidden");
        }
      }
    
      if (allgridButtons != undefined)
        for (let index = 0; index < allgridButtons.length; index++) {
          const element = allgridButtons[index];
          if (isPostSave)
            element.removeAttribute("hidden");
          else
            element.setAttribute("hidden", "");
        }
      if (editPanel != undefined) {
        for (let index = 0; index < editPanel.length; index++) {
          const element = editPanel[index];
          if (isPostSave)
            element.removeAttribute("hidden");
          else
            element.setAttribute("hidden", "");
        }
      }
    }
  }



  const createMessageBarContent = () => {
    switch (messageBarState.requestType) {
      case RequestType.SAVE_TP:
        return "✔ Training Plan Saved Successfully!";
      case RequestType.ACTIVATE_TP:
        return "✔ Training Plan activated Successfully!";
      case RequestType.APPLIED_TEMPLATE:
        return "✔ Training Plan Template Applied Successfully!";
      case RequestType.CREATE_TEMPLATE:
        return "✔ Training Plan Template Created Successfully!";
      case RequestType.ADD_US:
        return "✔ Unit Standard(s) Added Successfully!";
      case RequestType.ADD_RESULT:
        return "✔ Result Created Successfully!";
      case RequestType.UPDATE_RESULT:
        return "✔ Result Updated Successfully!";
      case RequestType.ADD_COMMENT:
        return "✔ Comment Added Successfully!";
      case RequestType.UPDATE_COMMENT:
        return "✔ Comment Updated Successfully!";
      case RequestType.SEND_EMAIL:
        return "✔ Email Sent Successfully!";
      case RequestType.ADD_ASSESSOR:
        return "✔ Assessor Added Successfully!";
      case RequestType.ADD_VERIFIER:
        return "✔ Verifier Added Successfully!";
      case RequestType.SAVE_ORDER:
        return "✔ Order has been saved Successfully!";
      case RequestType.CREATE_VISIT:
        return "✔ Visit has been saved Successfully!";
      case RequestType.UPDATE_VISIT:
        return "✔ Visit has been updated Successfully!";
      case RequestType.CREATE_GOAL:
        return "✔ Goal has been saved Successfully!";
      case RequestType.DELETE_GOAL:
        return "✔ Goal has been deleted Successfully!";
      case RequestType.UPDATE_GOAL:
        return "✔ Goal has been updated Successfully!";
      case RequestType.CREATE_VIEW:
        return "✔ Training Plan View has been created Successfully!";
      case RequestType.UPDATE_VIEW:
        return "✔ Training Plan View has been updated Succesfully!";
      case RequestType.DELETE_VIEW:
        return "✔ Training Plan View has been deleted Successfully!";
      default:
        return "";
    }
  }

  async function openBase64NewTab(base64Pdf: string) {
    let resp = await fetch(base64Pdf);
    let blob = await resp.blob();

    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }

  const sendEmail = async (isSendEmailToLearner: boolean) => {
    let globalContext = {
      ...globalAppContext,
      IsFullScreenLoaderVisible: true
    } as GlobalStateModel;
    globalAppContext.setGlobalState(globalContext);
    //addPageBreaks();
    let currentGrid = "dragAndDropGrid"
    preparePDF(false, currentGrid);
    let element = contentRef;
    if (element != null) {
      let group = await drawDOM(element.current as HTMLElement, { paperSize: "auto" });
      let dataUri = await exportPDF(group, { multiPage: true, margin: 15 });
      preparePDF(true, currentGrid);

      let sendEmailHelper = new SendEmailHelper(globalAppContext, isSendEmailToLearner, dataUri);
      await sendEmailHelper.initEmailProcess();
      globalContext = {
        ...globalAppContext,
        IsFullScreenLoaderVisible: false
      } as GlobalStateModel;
      globalAppContext.setGlobalState(globalContext);
      //openBase64NewTab(dataUri);
      //setPdfBase64String(dataUri);

    }
  }

  const showDirtyDialog = () => {
    return (
      <Dialog
        hidden={hideDialog}
        onDismiss={toggleHideDialog}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={toggleHideDialog} text="Ok" />
        </DialogFooter>
      </Dialog >);
  }
  const isReorderView = ddSelectedView != "Editableaddresult" && ddSelectedView != "Editablemanageunits"
  return (
    <React.StrictMode >
      {globalAppContext.IsFullScreenLoaderVisible ? <div className="loading">
        <div className='uil-ring-css' style={{ transform: "scale(0.79)" }}>
          <div></div>
        </div>
      </div> : <></>}
      <div
        id="masterDiv"
        ref={contentRef}
        className="bodyFont pageMargin"
      >
        {masterControlState.renderChildren ? (
          <Stack>
            <TrainingPlanViewsContext.Provider value = {{trainingPlanViews, updateTrainingPlanViews, onViewChange}}>
            <MasterControlContext.Provider value={dispatch}>
              <ReorderControlProvider>
              <MasterControlContextWarnings.Provider value={dispatchC}>
                <Stack id="topPanel" style={{ position: "sticky", top: 0, zIndex: 950, background: "white" }}>
                  <Stack style={{ marginBottom: "20px" }} horizontal>
                    <StackItem align="start">
                      <h1 className="header-text" id="training-title">Training plan</h1>
                    </StackItem>

                    <div className="topright">
                      <img
                        id="logo"
                        src={logo}
                        alt="Primary ITO - Knowledge to Grow"
                        className="header-logo"
                      />
                    </div>
                  </Stack>
                  <Stack> 
                  {/* <DefaultButton className="button-style grid-button" style={{ width: "70px", marginBottom: "15px" }} title="Print" iconProps={{ iconName: "Print" }} onClick={() => {
                    window.scrollTo(0, 0);
                    setTimeout(() => {
                      window.print();
                    }, 500);
                  }}></DefaultButton> */}
                  </Stack> 
                    <Stack id="editPanel" className="editPanel">
                      <Stack horizontal>
                        <Stack className="DropDownSection">
                          <span>
                            {" "}
                            <Dropdown
                              placeholder="Select options"
                              label="View:"
                              options={globalAppContext.IsPortalMode ? portalOptionsViewDD : trainingPlanViews}
                              styles={dropdownStyles}
                              selectedKey={ddSelectedView}
                              onChange={(e, option, index) => {
                                onViewChange(index as number);
                              }} />

                          </span>
                        </Stack>
                        
                        <ButtonPanelControl setPrinting = {setPrinting} contentRef={contentRef} isCompliant={isCompliant()} isDirty={globalAppContext.isDirty as DirtyMode} isReorderModeDirty={globalAppContext.IsReorderDirty as boolean} isColumnsDirty={globalAppContext.isColumnsDirty as boolean} isCustomView={globalAppContext.selectedView == Views.Custom} ></ButtonPanelControl>
                        {!globalAppContext.IsPortalMode && <React.Fragment>
                        {globalAppContext.isEditMode ? <TemplateSelector trainingPlanStatus={globalAppContext.trainingPlanObj?.statuscode as number} programmeId={globalAppContext.programmeObj?.tims_programmeid as string}></TemplateSelector> : ""}
                        <DefaultButton className="button-style" style={{ marginLeft: "3px", marginTop: "32px" }} onClick={async (e) => {
                          if (globalAppContext.IsReorderDirty || globalAppContext.isDirty?.isElecGrpSectionDirty || globalAppContext.isDirty?.isPrgrmElecSec) {
                            toggleHideDialog();
                            return;
                          }
                          window.scroll(0, 0);
                          setTimeout(async () => {
                            await sendEmail(true);
                          }, 1000);

                        }}>Send Email to Learner</DefaultButton>
                        <DefaultButton className="button-style" style={{ marginLeft: "3px", marginTop: "32px" }} onClick={async (e) => {
                          if (globalAppContext.IsReorderDirty || globalAppContext.isDirty?.isElecGrpSectionDirty || globalAppContext.isDirty?.isPrgrmElecSec) {
                            toggleHideDialog();
                            return;
                          }
                          window.scroll(0, 0);
                          setTimeout(async () => {
                            await sendEmail(false);
                          }, 1000);
                        }}>Send Email to Workplace</DefaultButton>
                        {showDirtyDialog()}
                        <StackItem style={{ paddingLeft: "5px", paddingTop: "37px" }}>
                          <Checkbox checked={isDetailSectionDisplayed} onChange={(e, checked) => { setIsDetailSectionDisplayed(checked as boolean); }} label="Details Section"></Checkbox>
                        </StackItem>
                        <StackItem style={{ paddingLeft: "5px", paddingTop: "37px" }}>
                          <Checkbox checked={globalAppContext.showSectionRules} onChange={(e, checked) => { hideShowSectionRules(); }} label="Section Rules"></Checkbox>
                        </StackItem>
                        </React.Fragment> }
                      </Stack>

                      <Stack style={messageBarState.showMessageBar ? { marginBottom: "10px", marginRight: "1%" } : undefined}>
                        {messageBarState.showMessageBar ?
                          <MessageBar onDismiss={(e) => {
                            setMessageBarState({ ...messageBarState, showMessageBar: false });
                          }} messageBarType={messageBarState.isSuccess ? MessageBarType.success : MessageBarType.error} isMultiline={true}>
                            {messageBarState.isSuccess ? createMessageBarContent() :
                              messageBarState.errors.map((val, index) => (
                                <div>{val}</div>
                              ))
                            }
                          </MessageBar> : ""}
                      </Stack>
                      <Stack style={masterControlStateC.showMessageBar ? { marginBottom: "10px", marginRight: "1%" } : undefined}>
                        {masterControlStateC.showMessageBar ?
                          <MessageBar onDismiss={(e) => {
                            dispatchC({ type: "HIDE_WARNINGS" });
                          }} messageBarType={MessageBarType.warning} isMultiline={true}>
                            {
                              masterControlStateC.allWarnings.map((val, index) => (
                                <div>{val}</div>
                              ))
                            }
                          </MessageBar> : ""}
                      </Stack>
                    </Stack>
                  <Collapse isOpened={isDetailSectionDisplayed || printing}>
                    <DetailsControl trainingProgramme={globalAppContext.trainingProgrammeObj as TrainingProgrammeModel} trainingPlan={globalAppContext.trainingPlanObj as TrainingPlanModel} contact={globalAppContext.contact as ContactModel}></DetailsControl>
                  </Collapse>
                </Stack>
                <div ref={gridPanelRef}>
                  <Stack id="gridPanel" style={{ marginRight: "1%" }}>
                    <Stack>
                      {isReorderView && <GoalsControl></GoalsControl> }

                      <Accordion HeaderElem={summarySection()} isSuperParentAccordion={true}>
                        {(ddSelectedView == "Editableaddresult" || ddSelectedView == "Editablemanageunits") && 
                          <React.Fragment>
                            {masterControlStateB.reloadControls ? <Stack style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                              <Loader
                                type="TailSpin"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={30000} //3 secs
                              />
                            </Stack> :
                              <React.Fragment>
                                <CompulsarySection ></CompulsarySection>
                                <ElectiveGroupSection></ElectiveGroupSection>
                                <ProgrammeElectiveSections></ProgrammeElectiveSections>
                              </React.Fragment>}
                          </React.Fragment> }
                          
                          {isReorderView && <ReorderControl></ReorderControl> }
                      </Accordion>
                    </Stack>
                  </Stack>
                </div>
              </MasterControlContextWarnings.Provider>
              </ReorderControlProvider>
            </MasterControlContext.Provider>
            </TrainingPlanViewsContext.Provider>
            <Stack tokens={{ childrenGap: 12 }} style={{ marginTop: "10px", marginRight: "1%" }}>
            </Stack>
          </Stack>
        ) : (
          <Stack className="centered">
            <Loader
              type="TailSpin"
              color="#00BFFF"
              height={100}
              width={100}
              timeout={30000} //3 secs
            />
          </Stack>
        )
        }
      </div >
    </React.StrictMode >
  );
}
