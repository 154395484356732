import { DefaultButton, Stack } from "@fluentui/react";
import { createContext, Fragment, useContext, useEffect, useReducer, useState } from "react";
import Loader from "react-loader-spinner";
import Common from "../Common/Common";
import GlobalState from "../GlobalState";
import { ContactModel } from "../Models/ContactModel";
import GlobalStateModel from "../Models/GlobalStateModel";
import CacheManager from "../Common/CacheManager";
import { ProgrammeModel } from "../Models/ProgrammeModel";
import { ResultModel } from "../Models/ResultModel";
import TokenModel from "../Models/TokenModel";
import { UnitStandardModel } from "../Models/UnitStandardModel";
import { Accordion } from "./Accordion";
import { CompulsarySection } from "./CompulsorySection";
import { SummaryObject } from "./ElectiveGroupControl";
import { ElectiveGroupSection } from "./ElectiveGroupSection";
import { ProgrammeElectiveSections } from "./ProgrammeElectiveSections";
import "../assets/css/PreviewControl.css";
import AsyncSelect from 'react-select/async';
import { ProgrammeStatuses } from "../Common/Enum";
import logo from "../assets/images/PrimaryITO_CMYK BLACK text-01.png";
import { Header } from "./Header";


interface PreviewControlProps {
    token: TokenModel,
    contactId: string,
    programmeId: string
}

interface PreviewControlState {
    tokenModel: TokenModel;
    contact: ContactModel;
    programmeObj: ProgrammeModel;
    renderChildren: boolean;
}


const headerLogoStyle = {
    marginLeft: "75%",
    marginTop: "0.9%",
    width: "200px"
};

export const PreviewControlContext = createContext({});

export const PreviewControl: React.FunctionComponent<PreviewControlProps> = (props) => {

    const globalAppContext = useContext(GlobalState);
    const [reloadControls, setReloadControls] = useState(false);

    const [previewControlState, setPreviewControlState] = useState({ contact: {}, programmeObj: {}, renderChildren: false, tokenModel: {} } as PreviewControlState)

    const previewControlReducer = (state: SummaryObject, action: any) => {
        switch (action.type) {
            case "UPDATE_SUMMARY":
                {
                    if (action.obj.checked)
                        return { 
                            id: state.id, selected: state.selected + action.obj.summaryObj.selected, 
                            selectedMin: state.selectedMin + action.obj.summaryObj.selectedMin, 
                            selectedMax: state.selectedMax + action.obj.summaryObj.selectedMax, 
                            remaining: state.remaining + action.obj.summaryObj.remaining, 
                            achieved: state.achieved + action.obj.summaryObj.achieved 
                        } as SummaryObject;
                    else
                        return { 
                            id: state.id, 
                            selected: state.selected - action.obj.summaryObj.selected, 
                            selectedMin: state.selectedMin - action.obj.summaryObj.selectedMin, 
                            selectedMax: state.selectedMax - action.obj.summaryObj.selectedMax,
                            remaining: state.remaining - action.obj.summaryObj.remaining, 
                            achieved: state.achieved - action.obj.summaryObj.achieved 
                        } as SummaryObject;
                }
            case "INIT_SUMMARY":
                return { 
                    id: state.id, 
                    selected: state.selected + action.obj.selected, 
                    selectedMin: state.selectedMin + action.obj.selectedMin, 
                    selectedMax: state.selectedMax + action.obj.selectedMax, 
                    remaining: state.remaining + action.obj.remaining, 
                    achieved: state.achieved + action.obj.achieved 
                } as SummaryObject;
            case "RESET_SUMMARY":
                return { id: state.id, selected: 0, remaining: 0, achieved: 0, selectedMin:0, selectedMax:0 } as SummaryObject;
            default:
                break;
        }
        return state;
    }
    const [previewControlStateB, dispatch] = useReducer(previewControlReducer, { achieved: 0, remaining: 0, selected: 0, selectedMin: 0, selectedMax:0, id: Common.newGuid() } as SummaryObject);

    useEffect(() => {
        const initializeDataLoad = async () => {

            let token = props.token;
            var isPortalMode =  props.token.loginType === 'portal';

            var contactModel = new ContactModel(token);
            var programmeModel = new ProgrammeModel(token);
            var unitStandardModel = new UnitStandardModel(token);
            var resultModel = new ResultModel(token);
            var contact = undefined;
            var programmeObj = undefined;
            if (props.contactId != "")
                contact = (await contactModel.retrieveContact(props.contactId as string) as ContactModel);
            if (props.programmeId != "")
                programmeObj = (await programmeModel.retrieveProgramme(props.programmeId as string)) as ProgrammeModel;

            let promiseArr = [];
            if (programmeObj != undefined) {
                let usmProm = unitStandardModel.initCache(programmeObj?.tims_programmeid as string, contact?.contactid as string);
                promiseArr.push(usmProm);
            }
            if (contact != undefined) {
                let resultMProm = resultModel.initCache(contact?.contactid as string, false);
                promiseArr.push(resultMProm)
            }
            await Promise.all(promiseArr);

            let globalContext = {
                tokenModel: token,
                trainingPlanObj: undefined,
                contact: contact,
                programmeObj: programmeObj,
                trainingProgrammeObj: undefined,
                trainingContractObj: undefined,
                isEditMode: true,
                isPreviewModeOnly: true,
                isCompliant: { isElecGrpCompliant: true, isPrgrmElecSecCompliant: true },
                IsPortalMode  : isPortalMode 
            } as GlobalStateModel;
            globalAppContext.setGlobalState(globalContext);
            setPreviewControlState({ tokenModel: token, programmeObj: programmeObj, contact: contact, renderChildren: true } as PreviewControlState);
        }
        initializeDataLoad();

        return () => {

        }
    }, []);

    const programmePromise = async (inputValue: string) => {
        if (inputValue.length < 4)
            return [];
        let programmeModel = new ProgrammeModel(globalAppContext.tokenModel as TokenModel);
        let programmes = await programmeModel.searchProgrammeByName(inputValue);
        return programmes;

    }

    const reloadProgramme = async (programme: ProgrammeModel) => {
        var unitStandardModel = new UnitStandardModel(globalAppContext.tokenModel as TokenModel);
        let globalContext = {
            ...globalAppContext,
            programmeObj: programme
        } as GlobalStateModel;
        globalAppContext.setGlobalState(globalContext);
        setPreviewControlState({ tokenModel: globalAppContext.tokenModel, programmeObj: programme, contact: globalAppContext.contact, renderChildren: true } as PreviewControlState);
        await unitStandardModel.initCache(programme.tims_programmeid as string, globalAppContext.contact?.contactid as string);

    }

    const isCompliant = () => {

        if (!globalAppContext.isCompliant?.isElecGrpCompliant || !globalAppContext.isCompliant?.isPrgrmElecSecCompliant)
            return false;

        if (previewControlState.programmeObj == undefined)
            return false;

            let minCredits = (previewControlState.programmeObj.tims_minimumcredits as number) ?? 0
            let maxCredits = (previewControlState.programmeObj.tims_maximumcredits as number) ?? 1000
        
            let minCreditsAtLevel = (previewControlState.programmeObj.tims_minimumcreditsonlevel as number) ?? 0
            let maxCreditsAtLevel = (previewControlState.programmeObj.tims_maximumcreditsonlevel as number) ?? 1000

        return ( (previewControlStateB.selected >= minCredits && previewControlStateB.selected <= maxCredits) 
        &&(previewControlStateB.selectedMin >= minCreditsAtLevel && previewControlStateB.selectedMax <= maxCreditsAtLevel)
                )
    }


    const summarySection = () => {
        return (
            <Header 
            showRules = {true}
            name = "Summary of credits on Plan"
            isDisabled = {false}
            isCompliant = {isCompliant()}
            progress = {
              {
                achieved:previewControlStateB.achieved,
                remaining:previewControlStateB.remaining
              }
            }
            credits = {
              {
                minAllowed:previewControlState.programmeObj?.tims_minimumcredits,
                maxAllowed:previewControlState.programmeObj?.tims_maximumcredits,
                selected:previewControlStateB.selected
              }
            }
            levels = {
              {
                minAllowed:previewControlState.programmeObj?.tims_minimumcreditsonlevel,
                maxAllowed:previewControlState.programmeObj?.tims_maximumcreditsonlevel,
                minAllowedLevel:previewControlState.programmeObj?.tims_minimumlevel,
                maxAllowedLevel:previewControlState.programmeObj?.tims_maximumlevel,
                selectedAtMinLevel:previewControlStateB.selectedMin,
                selectedAtMaxLevel:previewControlStateB.selectedMax
              }
            }
          >
          </Header>
        );
    }

    const printButtonDisabled = () => {
        if (previewControlState.programmeObj == undefined)
            return true;
        else
            return previewControlState.programmeObj.statuscode == ProgrammeStatuses.Pending
    }

    const getProgrammeUrlForCRM = () => {
        return globalAppContext.tokenModel?.apiUrl + `/main.aspx?etn=tims_programme&id=${globalAppContext.programmeObj?.tims_programmeid}&pagetype=entityrecord&forceUCI=1`;
    }

    return (<div className="pageMargin">
        {!previewControlState.renderChildren ? <Stack className="centered">
            <Loader
                type="TailSpin"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={30000} //3 secs
            />
            
        </Stack> : <Stack style={{ marginLeft: "10px" }}>
            <Stack style={{ position: "sticky", top: 0, zIndex: 999999, background: "white" }}>
                <Stack style={{ marginBottom: "20px" }} horizontal>
                    <h1 className="header-text" id="training-title">Training plan</h1>
                    <div className="topright">
                    <img
                      id="logo"
                      src={logo}
                      alt="Primary ITO - Knowledge to Grow"
                      className="header-logo"
                    />
                  </div>
                </Stack>
                <Stack>
                    <Stack horizontal style={{ marginBottom: "5px" }}>
                        <span style={{ width: '20%' }}><p><strong>Preview plan for:</strong></p></span>
                        <span><p>{previewControlState.contact?.fullname}</p></span>
                    </Stack>
                    <Stack horizontal style={{ marginBottom: "5px" }}>
                        <span style={{ width: '20%' }}><p><strong>Programme:</strong></p></span>
                        {!globalAppContext.IsPortalMode ? 
                            <span><a href={getProgrammeUrlForCRM()} target="_blank"><p>{previewControlState.programmeObj?.tims_name}</p></a></span>
                            :
                            <span><p>{previewControlState.programmeObj?.tims_name}</p></span>
                        }
                    </Stack>
                    <Stack horizontal style={{ marginBottom: "5px" }}>
                        <span style={{ width: '20%' }}><p><strong>Programme status:</strong></p></span>
                        <span><p>{Common.getFormattedValue(previewControlState.programmeObj, "statuscode")}</p></span>
                    </Stack>
                    <Stack className="editPanel" horizontal style={{ marginBottom: "10px" }}>
                        <span style={{ width: '20%' }}><p><strong>Change Programme:</strong></p></span>
                        <span style={{ width: '15%' }}> <AsyncSelect placeholder="Enter PR number or name" noOptionsMessage={() => { return "No Programme found." }} onChange={async (option) => {
                            reloadProgramme(option as ProgrammeModel);
                            setReloadControls(true);
                            dispatch({ type: "RESET_SUMMARY" });
                            setTimeout(() => {
                                setReloadControls(false);
                            }, 1000);

                        }} getOptionLabel={(option: ProgrammeModel) => { return option.tims_name as string; }}
                            getOptionValue={(option: ProgrammeModel) => { return option.tims_programmeid as string; }} cacheOptions loadOptions={programmePromise} />
                        </span>
                        &nbsp;
                        <span style={{ paddingTop: "4px" }}>
                            <DefaultButton className="button-style" disabled={printButtonDisabled()} onClick={() => {
                                window.scrollTo(0, 0);
                                setTimeout(() => {
                                    window.print();
                                }, 500);
                            }}>Print Plan</DefaultButton>
                        </span>
                        <span style={{ paddingTop: "4px", paddingLeft:"4px" }}>
                        <DefaultButton 
                            className="button-style" title="Refresh" iconProps={{ iconName: "Refresh" }} onClick={async () => { 
                                await CacheManager.clearCache(globalAppContext); window.location.reload(); 
                                }}>
                        </DefaultButton>
                        </span>

                    </Stack>
                </Stack>
            </Stack>
            <Stack>
                <Stack style={{ marginRight: "1%" }}>
                    <Accordion HeaderElem={summarySection()} isSuperParentAccordion={false}>
                        {reloadControls ? <Stack style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                            <Loader
                                type="TailSpin"
                                color="#00BFFF"
                                height={100}
                                width={100}
                                timeout={30000} //3 secs 
                            />
                        </Stack> : <PreviewControlContext.Provider value={dispatch}>
                            {globalAppContext.programmeObj == undefined ?
                                <div style={{ display: "block", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
                                    <b>Please select valid programme</b>
                                </div> :
                                <Fragment>
                                    <CompulsarySection></CompulsarySection>
                                    <ElectiveGroupSection></ElectiveGroupSection>
                                    <ProgrammeElectiveSections></ProgrammeElectiveSections>
                                </Fragment>
                            }
                        </PreviewControlContext.Provider>}

                    </Accordion>
                </Stack>
            </Stack>
        </Stack>}
    </div>);
}

